import flattenDeep from 'lodash/flattenDeep';
import cloneDeep from 'lodash/cloneDeep';
// Determine the index of an item if you flattened
// the nested array it is a child item of
export function getFlattenedIndexOf(nestedArr, item) {
  return flattenDeep(nestedArr).indexOf(item);
} //
// Deep Array Map
//
// create a new array with the same depth as the
// original, containing values provided by a
// callback function

export function deepArrayMap(arr, callback) {
  // Count times called as a replacment
  // for tracking the flattend index
  const wrappedCallback = (() => {
    let called = -1;
    return (value, depth, index, array) => {
      called += 1;
      return callback(value, called, depth, index, array);
    };
  })();

  return deepArrayMapper(arr, 0, wrappedCallback);
} // Recursively itterate over an array invoking
// callback on all non-array children

function deepArrayMapper(arr, depth, callback) {
  const result = cloneDeep(arr);
  let currentArray = arr;

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];

    if (Array.isArray(item)) {
      currentArray = item;
      result[i] = deepArrayMapper(item, depth + 1, callback);
    } else {
      result[i] = callback(item, depth, i, currentArray);
    }
  }

  return result;
} // Get the item at a specific index in an array


export function getItemAtIndex(index, arr) {
  return arr[index % arr.length];
}
export function getNextItemIndex(currentIndex, arrLength) {
  return (currentIndex + 1) % arrLength;
}